import React from "react"
import tw, { GlobalStyles as TWGlobal } from "twin.macro"
import { css, Global } from "@emotion/react"

const GlobalStyles = () => {
  return (
    <>
      <TWGlobal />
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
          }
          body {
            font-family: Space Mono;
            background-color: black;
            color: white;
          }
          h1 {
            ${tw`text-6xl xl:text-8xl`}
          }
          h2 {
            ${tw`text-3xl lg:text-5xl desktop-hd:text-6xl`}
          }
          h3 {
            ${tw`text-3xl lg:text-4xl desktop-hd:text-6xl`}
          }
          h4 {
            ${tw`text-xl lg:text-xl font-bold desktop-hd:text-2xl`}
          }

          p {
            ${tw`text-sm leading-8`}
          }

          a {
            ${tw`text-blue active:(underline) hover:(underline) focus:(outline outline-4 outline-offset-0 outline-purple)`}
          }

          .nav-link {
            ${tw`text-white px-1 opacity-50 text-3xl lg:text-5xl desktop-hd:text-6xl active:(underline) hover:(underline) focus:(!no-underline outline outline-4 outline-offset-0 outline-purple)`}
          }
          .current-nav-link {
            ${tw`text-white px-1 opacity-100 text-3xl lg:text-5xl desktop-hd:text-6xl active:(underline) hover:(underline) focus:(!no-underline outline outline-4 outline-offset-0 outline-purple)`}
          }

          @keyframes slideAnimation {
            from {
              transform: translateX(0%);
            }
            to {
              transform: translateX(-100%);
            }
          }

          .looper {
            width: 100%;
            overflow: hidden;
          }

          .looper__innerList {
            display: flex;
            justify-content: center;
            width: fit-content;
          }
          .looper__innerList[data-animate="true"] .looper__listInstance {
            animation: slideAnimation linear infinite;
          }

          .looper__listInstance {
            display: flex;
            width: max-content;

            animation: none;
          }
          @-webkit-keyframes rotating /* Safari and Chrome */ {
            from {
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
              transform-box: fill-box;
              transform-origin: center;
            }
            to {
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
              transform-box: fill-box;
              transform-origin: center;
            }
          }
          @keyframes rotating {
            from {
              -ms-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
              transform-box: fill-box;
              transform-origin: center;
            }
            to {
              -ms-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
              transform-box: fill-box;
              transform-origin: center;
            }
          }

          .rotatingRight {
            -webkit-animation: rotating 30s linear infinite;
            -moz-animation: rotating 30s linear infinite;
            -ms-animation: rotating 30s linear infinite;
            -o-animation: rotating 30s linear infinite;
            animation: rotating 30s linear infinite;
          }
          .rotatingLeft {
            -webkit-animation: rotating 30s linear infinite;
            -moz-animation: rotating 30s linear infinite;
            -ms-animation: rotating 30s linear infinite;
            -o-animation: rotating 30s linear infinite;
            animation: rotating 30s linear infinite;
            animation-direction: reverse;
          }
          .input {
            ${tw`p-3 rounded-md placeholder-transparent bg-black border border-white border-2 mb-4 text-sm`}
          }

          .input:-webkit-autofill {
            -webkit-text-fill-color: white;
            -webkit-box-shadow: 0 0 0 30px black inset !important;
          }
          .input:autofill {
            color: white;
            background-color: black !important;
          }

          .input-container label {
            ${tw`text-white text-xs absolute translate-y-4 translate-x-3 pointer-events-none`}
            transform-origin:0 0
          }

          .input-container {
            transition: all 0.3s;
          }
          .input:not(:placeholder-shown) {
            ${tw`px-2 pt-4`}
          }
          .floating-label-active {
            transform: translateY(0.4rem) translateX(0.6rem) scale(0.7) !important;
            color: #bf82c2 !important;
            transition: all 0.3s !important;
          }
          .input-container:focus-within label,
          .input:not(:placeholder-shown) + label {
            transform: translateY(0.4rem) translateX(0.6rem) scale(0.7);
            color: #bf82c2;
            transition: all 0.3s;
          }
        `}
      />
    </>
  )
}

export default GlobalStyles
